import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import NavigationItemSkeleton from '@views/project/project-aside/components/NavigationItemSkeleton'
import { getNavigationLink } from '@views/project/project-aside/helpers/get-navigation-link'
import FolderNavigationTitle from '@views/surveys/FolderNavigationTitle'
import {
  type FolderNavigationData,
  checkIsFolderOpen,
} from '@views/surveys/helpers/check-is-folder-open'

type Props = {
  folders: FolderNavigationData
}

export default function FolderNavigation({ folders }: Props) {
  const { query } = useRouter()

  const isFolderOpen = checkIsFolderOpen({
    children: folders?.children,
    folderLinkQuery: folders?.linkQuery,
    query: query as Record<string, string>,
  })

  const href = getNavigationLink(folders?.linkPathname, {
    ...query,
    ...folders?.linkQuery,
  })

  const isFolderLinkActive = isFolderOpen && query?.folderId === folders?.linkQuery.folderId

  if (!folders) {
    return <NavigationItemSkeleton />
  }

  //skip the root folder and just render the children
  if (!folders?.parentFolderId) {
    return (
      <div className='h-full overflow-y-auto'>
        {folders?.children?.map((child) => (
          <FolderNavigation key={child.id} folders={child} />
        ))}
      </div>
    )
  }

  return (
    <div className='flex flex-col space-y-1'>
      <Link
        className={classNames(
          'flex w-full items-center space-x-4 rounded-md px-4 py-2 text-sm font-medium transition ease-in-out',
          {
            'font-semibold text-branding': isFolderLinkActive,
          },
        )}
        href={href}
      >
        <FolderNavigationTitle
          isFolderOpen={isFolderOpen}
          name={folders?.name}
          subfoldersCount={folders.children.length}
        />
      </Link>

      {isFolderOpen &&
        folders?.children?.map((child) => {
          return (
            <div key={child.id} className='pl-6'>
              <FolderNavigation folders={child} />
            </div>
          )
        })}
    </div>
  )
}
