import React from 'react'

import classnames from 'classnames'
import { InputText } from 'primereact/inputtext'

import { Input } from '@components/Input'
import FolderOwnerDropdown from '@views/folder/FolderOwnerDropdown'

type Props = {
  clientId: string
  name: string
  onChange: (e) => void
  errors: { name?: string; clientId?: string }
  owner: string | null
}

export default function FolderCreateModalBody({ clientId, errors, name, onChange, owner }: Props) {
  return (
    <>
      <Input name='Folder name'>
        <InputText
          className={classnames('w-full', { ['p-invalid']: errors?.name })}
          name='name'
          value={name}
          onChange={onChange}
        />
        {errors?.name && <p className='mt-1 text-sm text-red-600'>{errors?.name}</p>}
      </Input>

      <Input name='Client ID'>
        <InputText className='w-full' name='clientId' value={clientId} onChange={onChange} />
      </Input>

      <FolderOwnerDropdown value={owner} onChange={onChange} />
    </>
  )
}
