import type { ZodType } from 'zod'

type ReturnType = {
  success: boolean
  errors: Record<string, string> | null
}

export function zodValidate(data: unknown, schema: ZodType): ReturnType {
  const safeParsed = schema.safeParse(data)

  if (safeParsed?.error) {
    const formattedErrors = safeParsed?.error.format()

    for (const key in formattedErrors) {
      const lastError = formattedErrors[key]?._errors?.[0]
      formattedErrors[key] = lastError
    }

    const { _errors, ...errors } = formattedErrors

    return { success: false, errors }
  }

  return { success: true, errors: null }
}
