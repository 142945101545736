import useSWR from 'swr'

import type { Folder } from '@typings/Folder'

type RootFolderReturnType = {
  rootFolder?: Folder
  rootFolderError: any
  isLoadingRootFolder: boolean
}

export function useRootFolder(): RootFolderReturnType {
  const { data, error, isLoading } = useSWR<Folder>('/api/folder/organisation/client')

  return {
    rootFolder: data,
    rootFolderError: error,
    isLoadingRootFolder: isLoading,
  }
}
