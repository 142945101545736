import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { NO_REVALIDATION } from 'config/swr/options'
import { useRouter } from 'next/router'
import toast from 'react-hot-toast'

import Modal from '@components/Modal'
import { useFolders } from '@hooks/use-survey-provider-service-api'
import FolderCreateModalBody from '@views/folder/FolderCreateModal/FolderCreateModalBody'
import FolderCreateModalFooter from '@views/folder/FolderCreateModal/FolderCreateModalFooter'
import { validateFolder } from '@views/folder/helpers/validate-folder'

type Props = {
  isCreateFolderModalVisible: boolean
  onModalClose: () => void
  setIsVisible: (boolean) => void
  parentFolderId: string | null
}

type FolderData = {
  name: string
  clientId: string
  parentFolderId: string | null
  owner: string | null
}

export default function FolderCreateModal({
  isCreateFolderModalVisible,
  onModalClose,
  parentFolderId,
  setIsVisible,
}: Props) {
  const router = useRouter()
  const { mutateFolders } = useFolders({ archived: false }, NO_REVALIDATION)

  const defaultFolderData: FolderData = {
    name: '',
    clientId: '',
    parentFolderId,
    owner: null,
  }

  const [folderData, setFolderData] = useState<FolderData>(defaultFolderData)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<Record<string, string> | null>(null)

  useEffect(() => {
    //ensure that parentFolderId is updated
    setFolderData(defaultFolderData)
  }, [parentFolderId])

  const handleCreateFolder = async () => {
    setIsLoading(true)

    const [isSuccess, errors] = validateFolder(folderData)

    if (!isSuccess) {
      setErrors(errors)
      setIsLoading(false)

      return
    }

    try {
      const { data, status } = await axios.post(`/api/folder`, {
        ...folderData,
        clientId: folderData.clientId ? folderData.clientId : `clientId-${Date.now()}`,
        updatedBy: 'tech',
      })
      if (status === 200) {
        mutateFolders?.()
        toast.success(`Folder has been created.`)
        router.push(`/folder/${data.id}`)
      }
    } catch (e) {
      toast.error('Something went wrong, please contact tech team!')
    } finally {
      setIsVisible(false)
      setIsLoading(false)
      setFolderData(defaultFolderData)
    }
  }

  const handleFolderDataChange = (e) => {
    setErrors(null)
    const name = e.target?.name
    const value = e.target?.value

    setFolderData((currentData) => ({
      ...currentData,
      [name]: value,
    }))
  }

  const isCreateFolderDisabled = !!errors || isLoading

  return (
    <Modal
      footer={
        <FolderCreateModalFooter
          handleCreateFolder={handleCreateFolder}
          isDisabled={isCreateFolderDisabled}
          isLoading={isLoading}
          onModalClose={onModalClose}
        />
      }
      isVisible={isCreateFolderModalVisible}
      size='md'
      title='Create folder'
      onModalClose={onModalClose}
    >
      <FolderCreateModalBody
        clientId={folderData?.clientId}
        errors={errors}
        name={folderData?.name}
        owner={folderData?.owner}
        onChange={handleFolderDataChange}
      />
    </Modal>
  )
}
