import { CaretRight, Folder } from '@phosphor-icons/react'

import { cx } from '@helpers/cx'

type Props = {
  subfoldersCount: number
  isFolderOpen: boolean
  name: string
}

export default function FolderNavigationTitle({ isFolderOpen, name, subfoldersCount }: Props) {
  return (
    <div className='flex items-start gap-x-2'>
      {subfoldersCount > 0 ? (
        <CaretRight className={cx('transition', { 'rotate-90': isFolderOpen })} size={20} />
      ) : (
        <Folder size={20} />
      )}
      <span>{name}</span>
    </div>
  )
}
