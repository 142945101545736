import { useRef } from 'react'

import { Tooltip, type TooltipProps } from 'primereact/tooltip'

type Props = {
  text: TooltipProps['content']
  position?: TooltipProps['position']
  tooltipClassName?: string
}

export default function ExplanationTooltip({ position, text, tooltipClassName }: Props) {
  const element = useRef<HTMLSpanElement>(null)

  return (
    <>
      <Tooltip
        className={tooltipClassName}
        content={text}
        position={position || 'bottom'}
        target={element.current}
      />
      <span ref={element} className='cursor-help'>
        (?)
      </span>
    </>
  )
}
