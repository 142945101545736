import classNames from 'classnames'
import Link from 'next/link'

import CountIndicator from '@views/project/project-aside/components/CountIndicator'

type Props = {
  count: number
  href: string
  icon?: string
  isActive: boolean
  name: string
}

export default function NavigationSubLink({ count, href, icon, isActive, name }: Props) {
  return (
    <li key={name}>
      <Link
        className={classNames(
          'flex w-full items-center py-2 text-sm transition ease-in-out hover:text-branding',
          {
            'font-semibold text-branding': isActive,
          },
        )}
        href={href}
      >
        {icon && <i className={classNames('mr-2 text-xs', icon)} />}
        {name}
        <CountIndicator count={count} />
      </Link>
    </li>
  )
}
