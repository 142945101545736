import type { Actions, Subjects } from '@hooks/use-permissions'

export type FolderNavigationData = {
  name: string
  id: string
  linkPathname: string
  linkQuery: Record<string, string>
  parentFolderId: string | null
  children?: Array<{
    name: string
    children: any
    linkPathname: string
    linkQuery: Record<string, string>
    parentFolderId: string | null
    id: string
  }>
  permission?: [Actions, Subjects]
  isDynamic?: boolean
}

type CheckIsFolderOpenArgs = {
  children: FolderNavigationData['children']
  query: Record<string, string>
  folderLinkQuery: FolderNavigationData['linkQuery']
}

export function checkIsFolderOpen({ children, folderLinkQuery, query }: CheckIsFolderOpenArgs) {
  const isFolderOpen = query?.folderId === folderLinkQuery?.folderId

  const isFolderChildrenOpen = children?.some((child) => {
    const isChildFolderOpen = child?.linkQuery?.folderId === query.folderId

    const isNestedChildFolderOpen = checkIsFolderOpen({
      children: child?.children,
      folderLinkQuery: child?.linkQuery,
      query,
    })

    return isChildFolderOpen || isNestedChildFolderOpen
  })

  return isFolderOpen || isFolderChildrenOpen
}
