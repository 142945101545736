import { useState } from 'react'

import { Plus } from '@phosphor-icons/react'
import { Button } from 'primereact/button'

import { useFolders } from '@hooks/use-survey-provider-service-api'
import FolderCreateModal from '@views/folder/FolderCreateModal'
import { useRootFolder } from '@views/folder/hooks/use-root-folder'
import FolderNavigation from '@views/surveys/FolderNavigation'
import { getFolderHierarchy } from '@views/surveys/helpers/get-folder-hierarchy'

export default function FoldersAside() {
  const [isCreateFolderModalVisible, setCreateFolderModalVisible] = useState(false)

  const { rootFolder } = useRootFolder()
  const { folders } = useFolders({ archived: false })
  const [foldersHierarchy] = getFolderHierarchy(folders, null) || []

  return (
    <div className='-mx-4 overflow-y-auto border-t px-4'>
      <div className='h6 mb-2 mt-6 flex items-center justify-between pl-2 text-gray-600'>
        <span>Folders</span>

        <Button
          className='p-button p-button-link !w-4'
          icon={<Plus className='text-gray-600' />}
          size='small'
          onClick={() => setCreateFolderModalVisible(true)}
        />
      </div>

      {isCreateFolderModalVisible && (
        <FolderCreateModal
          isCreateFolderModalVisible={isCreateFolderModalVisible}
          parentFolderId={rootFolder.id}
          setIsVisible={setCreateFolderModalVisible}
          onModalClose={() => setCreateFolderModalVisible(false)}
        />
      )}

      <FolderNavigation folders={foldersHierarchy} />
    </div>
  )
}
