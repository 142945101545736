import { useRouter } from 'next/router'
import { Skeleton } from 'primereact/skeleton'

import NavigationSubAddItem from '@views/project/project-aside/components/NavigationSubAddItem'
import NavigationSubLink from '@views/project/project-aside/components/NavigationSubLink'
import type { NavigationItem } from '@views/project/project-aside/data/navigation'
import { checkIsSubLinkActive } from '@views/project/project-aside/helpers/check-is-link-active'
import { getNavigationLink } from '@views/project/project-aside/helpers/get-navigation-link'

type Props = {
  isLoading: boolean
  openModal?: () => void
} & NavigationItem

const addItemNavigationSubIds = ['add-dashboard', 'add-calendar']

export default function NavigationSub({ children, id, isLoading, openModal }: Props) {
  const { pathname, query } = useRouter()

  return (
    <ul className='relative py-2 pl-14'>
      {children?.map(({ count, icon, id, isNavigationHidden, linkPathname, linkQuery, name }) => {
        if (isNavigationHidden) {
          return null
        }

        const isActive = checkIsSubLinkActive(pathname, query, {
          linkPathname,
          linkQuery,
        })

        const href = getNavigationLink(linkPathname, { ...query, ...linkQuery })

        const isAddItemNavigationButton = addItemNavigationSubIds.includes(id)

        if (isAddItemNavigationButton) {
          return (
            <NavigationSubAddItem
              key={name}
              icon={icon}
              isActive={isActive}
              name={name}
              openModal={openModal}
            />
          )
        }

        return (
          <NavigationSubLink key={name} count={count} href={href} isActive={isActive} name={name} />
        )
      })}

      {id === 'responses' && isLoading && (
        <li className='mt-2 space-y-3'>
          <Skeleton height='0.75rem' width='9rem' />
          <Skeleton height='0.75rem' width='7rem' />
        </li>
      )}
    </ul>
  )
}
