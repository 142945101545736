import type { NextRouter } from 'next/router'

type Query = NextRouter['query']

// expecting to replace `surveyId` and `activeTab` in the linkPathname
export function getNavigationLink(linkPathname: string, query: Query) {
  return linkPathname
    ?.replace('[surveyId]', query?.surveyId?.toString())
    ?.replace('[activeTab]', query?.activeTab?.toString())
    ?.replace('[dashboardId]', query?.dashboardId?.toString())
    ?.replace('[calendarId]', query?.calendarId?.toString())
    ?.replace('[folderId]', query?.folderId?.toString())
}
