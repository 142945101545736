import qs from 'query-string'
import useSWR, { KeyedMutator, SWRConfiguration } from 'swr'

import { usePermissions } from '@hooks/use-permissions'
import { useSurveyId } from '@hooks/use-survey-id'
import { useUser } from '@hooks/use-user'

import type { ReportingColumn } from '@typings/Reporting'
import type { Currency, ProjectBudgetStatsList, ProjectsStats } from '@typings/Survey'
import type { UserRoles, Users } from '@typings/Users'

type UseUsersReturnType = {
  users: Users
  usersError: any
  mutateUsers: KeyedMutator<Users>
  isLoadingUsers: boolean
  isValidatingUsers: boolean
}

export function useUsers(options?: SWRConfiguration): UseUsersReturnType {
  const { user } = useUser()
  const { organisationId } = user

  const { data, error, isLoading, isValidating, mutate } = useSWR<Users>(
    organisationId && `/api/organisation/${organisationId}/users`,
    options,
  )

  return {
    users: data,
    usersError: error,
    mutateUsers: mutate,
    isLoadingUsers: isLoading,
    isValidatingUsers: isValidating,
  }
}

type UseRolesReturnType = {
  roles: UserRoles
  rolesError: any
  mutateRoles: KeyedMutator<UserRoles>
  isLoadingRoles: boolean
  isValidatingRoles: boolean
}

export function useRoles(options?: SWRConfiguration): UseRolesReturnType {
  const { user } = useUser()
  const { organisationId } = user

  const { data, error, isLoading, isValidating, mutate } = useSWR<UserRoles>(
    organisationId && `/api/organisation/${organisationId}/roles`,
    options,
  )

  return {
    roles: data,
    rolesError: error,
    mutateRoles: mutate,
    isLoadingRoles: isLoading,
    isValidatingRoles: isValidating,
  }
}

type UseProjectStats = {
  projectsStats: ProjectsStats
  projectsStatsError: any
  mutateProjectsStats: KeyedMutator<ProjectsStats>
  isLoadingProjectsStats: boolean
  isValidatingProjectsStats: boolean
}

export function useProjectsStats(options?: SWRConfiguration): UseProjectStats {
  const { user } = useUser()
  const { organisationId } = user
  const { can } = usePermissions()

  const canViewAnalytics = can('read', 'Analytics')

  const { data, error, isLoading, isValidating, mutate } = useSWR<ProjectsStats>(
    organisationId && canViewAnalytics && `/api/organisation/${organisationId}/progress`,
    options,
  )

  return {
    projectsStats: data,
    projectsStatsError: error,
    mutateProjectsStats: mutate,
    isLoadingProjectsStats: isLoading,
    isValidatingProjectsStats: isValidating,
  }
}

type UseProjectBudgetStatsReturnType = {
  projectBudgetStats: ProjectBudgetStatsList
  projectBudgetStatsError: any
  mutateProjectBudgetStats: KeyedMutator<ProjectBudgetStatsList>
  isLoadingProjectBudgetStats: boolean
  isValidatingProjectBudgetStats: boolean
}

export function useProjectBudgetStats(
  projectId: string,
  currencies?: Array<Currency>,
  options?: SWRConfiguration,
): UseProjectBudgetStatsReturnType {
  const {
    user: { organisationId },
  } = useUser()

  const projectBudgetUrl = qs.stringifyUrl(
    {
      url: `/api/organisation/${organisationId}/project/${projectId}/spend`,
      query: { currencies },
    },
    { arrayFormat: 'comma' },
  )

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    projectId && projectBudgetUrl,
    options,
  )

  return {
    projectBudgetStats: data,
    projectBudgetStatsError: error,
    mutateProjectBudgetStats: mutate,
    isLoadingProjectBudgetStats: isLoading,
    isValidatingProjectBudgetStats: isValidating,
  }
}

type UseReportingColumns = {
  reportingColumns: Array<ReportingColumn>
  reportingColumnsError: any
  mutateReportingColumns: KeyedMutator<Array<ReportingColumn>>
  isLoadingReportingColumns: boolean
  isValidatingReportingColumns: boolean
}

export function useReportingColumns(options?: SWRConfiguration): UseReportingColumns {
  const surveyId = useSurveyId()
  const {
    user: { organisationId },
  } = useUser()

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    organisationId &&
      surveyId &&
      `/api/organisation/${organisationId}/project/${surveyId}/reporting/columns?includePII=true`,
    options,
  )

  return {
    reportingColumns: data,
    reportingColumnsError: error,
    mutateReportingColumns: mutate,
    isLoadingReportingColumns: isLoading,
    isValidatingReportingColumns: isValidating,
  }
}
