import type { FolderNavigationData } from '@views/surveys/helpers/check-is-folder-open'

import type { Folder } from '@typings/Folder'

export const getFolderHierarchy = (
  folders: Array<Folder>,
  parentId: string | null,
): Array<FolderNavigationData> => {
  return folders?.reduce((acc, folder) => {
    if (!(folder.parentFolderId === parentId)) {
      return acc
    }

    const folderItem = {
      name: folder.name,
      id: folder.id,
      linkPathname: `/folder/[folderId]`,
      linkQuery: { folderId: folder.id },
      parentFolderId: folder.parentFolderId,
      children: getFolderHierarchy(folders, folder.id),
    }

    return [...acc, folderItem]
  }, [])
}
