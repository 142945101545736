import {
  CalendarBlank,
  CreditCard,
  Files,
  Gear,
  House,
  Link,
  SquaresFour,
  UsersThree,
  Wrench,
} from '@phosphor-icons/react'

import { type Actions, type Subjects } from '@hooks/use-permissions'

export type NavigationItem = {
  name: string
  id: string
  icon?: React.ReactElement
  linkPathname: string
  linkQuery?: Record<string, string>
  isBeta?: boolean
  children?: Array<{
    name: string
    title?: string
    count?: number
    linkPathname: string
    linkQuery?: Record<string, string>
    permission?: [Actions, Subjects]
    readOnly?: boolean
    icon?: string
    id?: string
    isNavigationHidden?: boolean
  }>
  permission?: [Actions, Subjects]
  isDynamic?: boolean
}

export type NavigationData = Record<string, NavigationItem>

export const DASHBOARDS_NAVIGATION_ID = 'dashboards'
export const SCHEDULING_NAVIGATION_ID = 'scheduling'

export const EMAILING_PATHS = [
  '/survey/[surveyId]/distribution/emailing',
  '/survey/[surveyId]/distribution/emailing/emails',
  '/survey/[surveyId]/distribution/emailing/recipients',
  '/survey/[surveyId]/distribution/emailing/scheduled-emails',
]

export const navigationData: NavigationData = {
  overview: {
    name: 'Overview',
    icon: <House />,
    id: 'overview',
    linkPathname: `/survey/[surveyId]`,
  },
  dashboards: {
    name: 'Dashboards',
    icon: <SquaresFour />,
    id: DASHBOARDS_NAVIGATION_ID,
    linkPathname: '/survey/[surveyId]/dashboard/[dashboardId]',
    permission: ['read', 'Dashboard'],
  },
  responses: {
    name: 'Responses',
    icon: <UsersThree />,
    id: 'responses',
    linkPathname: `/survey/[surveyId]/decisions/[activeTab]`,
    linkQuery: { activeTab: 'ready-for-review' },
    permission: ['read', 'Responses'],
  },
  payments: {
    name: 'Payments',
    icon: <CreditCard />,
    id: 'payments',
    linkPathname: `/survey/[surveyId]/payments`,
    permission: ['manage', 'Payment'],
  },
  distribution: {
    name: 'Distribution',
    icon: <Link />,
    id: 'distribution',
    linkPathname: '/survey/[surveyId]/distribution/links',
    permission: ['create', 'Link'],
    children: [
      {
        name: 'Links',
        title: 'Generated links',
        linkPathname: '/survey/[surveyId]/distribution/links',
      },
      {
        name: 'Participants',
        title: 'Participants',
        linkPathname: '/survey/[surveyId]/distribution/participants',
      },
      {
        name: 'Emailing',
        title: 'Emailing',
        linkPathname: '/survey/[surveyId]/distribution/emailing',
      },
      {
        name: 'Panels',
        title: 'Panels',
        linkPathname: '/survey/[surveyId]/distribution/panels',
      },
      {
        name: 'Offline mode',
        title: 'Offline mode',
        linkPathname: '/survey/[surveyId]/distribution/offline-mode',
      },
      {
        name: 'Find & notify participants',
        title: 'Find & notify participants',
        linkPathname: '/survey/[surveyId]/distribution/find-notify-participants',
        isNavigationHidden: true,
      },
    ],
  },
  scheduling: {
    name: 'Scheduling',
    icon: <CalendarBlank />,
    id: SCHEDULING_NAVIGATION_ID,
    linkPathname: '/survey/[surveyId]/scheduling/[calendarId]',
    permission: ['read', 'Calendar'],
    isDynamic: true,
  },
  builder: {
    name: 'Builder',
    icon: <Wrench />,
    id: 'builder',
    linkPathname: '/survey/[surveyId]/builder',
    permission: ['read', 'Definition'],
  },
  reporting: {
    name: 'Reporting',
    icon: <Files />,
    id: 'reporting',
    isBeta: true,
    linkPathname: '/survey/[surveyId]/reporting',
  },
  settings: {
    name: 'Settings',
    icon: <Gear />,
    id: 'settings',
    linkPathname: `/survey/[surveyId]/settings/[activeTab]`,
    linkQuery: { activeTab: 'general' },
    //this hides the whole settings page (was a temporarily solution to hide the quotas tab) before we implement view:settings permission
    permission: ['read', 'Definition'],
    children: [
      {
        name: 'General',
        title: 'General settings',
        linkPathname: '/survey/[surveyId]/settings/[activeTab]',
        linkQuery: { activeTab: 'general' },
      },
      {
        name: 'Quotas',
        title: 'Quotas settings',
        linkPathname: '/survey/[surveyId]/settings/[activeTab]',
        linkQuery: { activeTab: 'quotas' },
        permission: ['read', 'Quota'],
      },
      {
        name: 'Targeting',
        title: 'Targeting settings',
        linkPathname: '/survey/[surveyId]/settings/[activeTab]',
        linkQuery: { activeTab: 'targeting' },
      },
      {
        name: 'Integrations',
        title: 'Integrations settings',
        linkPathname: '/survey/[surveyId]/settings/[activeTab]',
        linkQuery: { activeTab: 'integrations' },
        permission: ['read', 'Project'],
        readOnly: true,
      },
    ],
  },
}
