import React from 'react'

import { Button } from 'primereact/button'

type Props = {
  handleCreateFolder: () => void
  onModalClose: () => void
  isLoading: boolean
  isDisabled: boolean
}

export default function FolderCreateModalFooter({
  handleCreateFolder,
  isDisabled,
  isLoading,
  onModalClose,
}: Props) {
  return (
    <>
      <Button className='p-button-sm p-button-outlined p-button-secondary' onClick={onModalClose}>
        Cancel
      </Button>

      <Button
        className='p-button p-button-primary p-button-sm'
        disabled={isDisabled}
        label='Create folder'
        loading={isLoading}
        onClick={handleCreateFolder}
      />
    </>
  )
}
