import classNames from 'classnames'

type Props = {
  icon?: string
  isActive: boolean
  name: string
  openModal: () => void
}

export default function NavigationSubAddItem({ icon, isActive, name, openModal }: Props) {
  return (
    <li>
      <button
        className={classNames(
          'flex w-full items-center py-2 text-sm transition ease-in-out hover:text-branding',
          {
            'font-semibold text-branding': isActive,
          },
        )}
        onClick={openModal}
      >
        {icon && <i className={classNames('mr-2 text-xs', icon)} />}
        {name}
      </button>
    </li>
  )
}
