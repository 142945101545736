import z from 'zod'

import { zodValidate } from '@helpers/zod-validate'

const folderSchema = z.object({
  name: z.string().min(1, { message: 'Enter valid folder name' }).max(64),
})

export function validateFolder(folderData: any): [boolean, Record<string, string> | null] {
  const validationResult = zodValidate(folderData, folderSchema)

  return [validationResult.success, validationResult.errors]
}
