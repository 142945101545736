export const checkIsSublinkOpen = (
  pathname: string,
  query: Record<string, string | Array<string>>,
  linkQuery: Record<string, string>,
) => {
  const isDashboard = pathname.includes('dashboard')
  const isScheduling = pathname.includes('scheduling')

  if (isDashboard) {
    return query.dashboardId === linkQuery?.dashboardId
  }

  if (isScheduling) {
    return query.calendarId === linkQuery?.calendarId
  }

  return query.activeTab === linkQuery?.activeTab
}
