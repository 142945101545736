type Props = {
  count: number
}

export default function CountIndicator({ count }: Props) {
  if (!count) {
    return null
  }

  return (
    <span className='ml-2 inline-block h-4 min-w-[1.25rem] rounded-xl bg-gray-100 px-1.5 text-center align-text-bottom text-[10px] font-semibold leading-4 text-gray-600'>
      {count}
    </span>
  )
}
