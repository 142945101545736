import { Dropdown } from 'primereact/dropdown'
import { Skeleton } from 'primereact/skeleton'

import { Input } from '@components/Input'
import { useFlags } from '@hooks/use-flags'
import { useUsers } from '@hooks/use-pµs-api'

import type { Member } from '@typings/User'

type Props = {
  value: string
  onChange: (e) => void
}

const folderOwnerDropdownValue = (item: Member) => {
  const { email, name, picture } = item || {}

  return (
    <div className='flex items-center space-x-3'>
      <img alt='' className='rounded-md' height={32} src={picture} width={32} />
      <div className='flex flex-col leading-none'>
        <span className='mb-0.5'>{name}</span>
        <span className='text-xs text-gray-400'>{email}</span>
      </div>
    </div>
  )
}

const qrowdsyEmailRegex = /@iqurate.com|@qrowdsy.com/

export default function FolderOwnerDropdown({ onChange, value }: Props) {
  const flags = useFlags()
  const { isLoadingUsers, users } = useUsers()

  const options = flags.showQrowdsyProductOwners
    ? users
    : users?.filter((member) => !qrowdsyEmailRegex.test(member?.email))

  return (
    <Input name='Folder owner'>
      {isLoadingUsers ? (
        <Skeleton className='w-full' height='3.5rem' />
      ) : (
        <Dropdown
          className='h-14 w-full'
          name='owner'
          optionLabel='name'
          options={options}
          optionValue='email'
          value={value}
          valueTemplate={folderOwnerDropdownValue}
          onChange={onChange}
        />
      )}
    </Input>
  )
}
